export const rejectReasonOptions = () => [
  'profile',
  'technical_knowledge',
  'seniority',
  'education',
  'desired_salary',
  'hired_other',
  'other_reason',
  'hired_by_other_company',
  'missed_interview',
  'candidate_gave_up'
]

export const reasonsLangPath = (rejectionReason) =>
  `rejection.reasons.${rejectionReason}`

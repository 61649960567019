<template>
  <section class="applicant-rejected-reason">
    <template v-if="reasonSent">
      <p class="applicant-rejected-reason__label">
        {{ $t(`${langPath}.reasonSent`) }}
      </p>
      <p class="applicant-rejected-reason__description">
        {{ rejectReason }}
      </p>
    </template>
    <template v-else>
      <p class="applicant-rejected-reason__label">
        {{ $t(`${langPath}.reasonNotSent`) }}
      </p>
      <app-select
        v-model="refuseReason"
        v-validate="'required'"
        has-placeholder
        class="applicant-rejected-reason__select"
        data-test="refuse-reason"
        name="rejectionReason"
        :options="reasonOptions"
        :placeholder="$t(`${langPath}.rejectionReason`)"
        :data-vv-as="$t(`${langPath}.rejectionReason`)"
        data-vv-validate-on="blur"
        @input="$emit('input', refuseReason)"
      />
      <section class="applicant-rejected-reason__info-section">
        <app-assistive-text
          :assistive-text="$t(`${langPath}.rejectionInfo`)"
          class="applicant-rejected-reason__info-text"
          data-test="info-text"
        />
        <icon-base
          class="applicant-rejected-reason__info-icon"
          data-test="info-icon"
          icon-name="info"
        />
      </section>
    </template>
  </section>
</template>

<script>
import { rejectReasonOptions, reasonsLangPath } from '@/helpers/reject'

import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'
import IconBase from '@/components/IconBase'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantRejectedReason',
  components: { IconBase },
  data () {
    return {
      langPath: __langpath,
      refuseReason: ''
    }
  },
  computed: {
    ...applicantsHelper.mapGetters(['applicant']),
    reasonOptions () {
      return (rejectReasonOptions()).map(item => ({
        label: this.$t(`${reasonsLangPath(item)}`),
        value: item
      }))
    },
    rejectReason () {
      return this.$t(
        `${reasonsLangPath(this.applicant?.rejectionReason)}`
      )
    },
    reasonSent () {
      return !!this.applicant?.rejectionReason
    }
  }
}
</script>

<style lang="scss">
.applicant-rejected-reason {
  &__label {
    @extend %subtitle1;
  }

  &__description {
    @extend %body-text1;

    margin-top: $base*3;
  }

  &__info-section {
    align-items: center;
    color: $gray60;
    display: flex;
    height: $base*6;
    justify-content: space-between;
  }

  &__info-text {
    padding-top: 0;
  }

  &__select {
    margin-top: $base*4;
  }
}
</style>

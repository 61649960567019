<template>
  <section class="applicant-rejected-feedback">
    <template v-if="feedbackSent">
      <p class="applicant-rejected-feedback__label">
        {{ $t(`${langPath}.rejectionFeedbackSent`) }}
      </p>
      <p
        data-test="feedback-message"
        class="applicant-rejected-feedback__description"
      >
        {{ rejectFeedback }}
      </p>
    </template>
    <template v-else>
      <p class="applicant-rejected-feedback__label">
        {{ $t(`${langPath}.rejectionFeedbackNotSent`) }}
      </p>
      <p
        class="applicant-rejected-feedback__description"
        data-test="rejection-feedback-description"
      >
        {{ $t(`${langPath}.rejectionFeedbackDescription`, { name: candidateName }) }}
      </p>
      <app-text-area
        v-model="refuseFeedback"
        has-placeholder
        class="applicant-rejected-feedback__text"
        data-test="refuse-feedback"
        name="rejectionFeedback"
        :placeholder="$t(`${langPath}.rejectionPlaceholder`)"
        data-vv-as="Feedback"
        data-vv-validate-on="blur"
        vee-validate-initial="required"
        :disabled-status="noFeedback"
        :label-flag="$t(`${langPath}.rejectionFeedbackLabel`)"
        @change="$emit('change', noFeedback ? '' : refuseFeedback)"
        @input="$emit('input', noFeedback ? '' : refuseFeedback)"
      />
      <app-checkbox
        v-if="rejectionReasonEmpty"
        v-model="noFeedback"
        name="no-feedback"
        :label="$t(`${langPath}.feedbackCheckboxLabel`)"
        @click="$emit('input', noFeedback ? '' : refuseFeedback)"
        @input="$emit('input', noFeedback ? '' : refuseFeedback)"
      />
    </template>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMPANY } from '@/store/namespaces'

const companyHelper = createNamespacedHelpers(COMPANY)

export default {
  name: 'ApplicantRejectedFeedback',
  props: {
    showTemplateFeedback: {
      type: Boolean,
      default: false
    },
    applicant: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      refuseFeedback: '',
      noFeedback: false
    }
  },
  computed: {
    ...companyHelper.mapState(['company']),
    rejectionReasonEmpty () { return !this.applicant?.rejectionReason },
    candidateName () {
      return (this.applicant?.candidate?.name || '').split(' ')[0]
    },
    rejectFeedback () { return this.applicant?.rejectionMessage },
    feedbackSent () { return !!this.applicant?.rejectionMessage }
  },
  watch: {
    showTemplateFeedback (val) {
      if (val && !this.refuseFeedback) {
        const template = this.$t(`${this.langPath}.defaultRejectionFeedback`, {
          companyName: this.company?.name
        })
        this.refuseFeedback = template
        this.$emit('input', this.refuseFeedback)
      }
    }
  }
}
</script>

<style lang="scss">
.applicant-rejected-feedback {
  margin-top: $base*4;

  &__label {
    @extend %subtitle1;
  }

  &__description {
    @extend %body-text1;

    margin-top: $base*3;
  }
}
</style>
